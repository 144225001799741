import {
  AfterViewInit,
  Component, effect,
  ElementRef,
  HostListener, inject, Injector,
  OnDestroy,
  OnInit, QueryList, viewChild, ViewChild, ViewChildren,
} from '@angular/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import { createSelector, select, Store } from "@ngrx/store";
import { AppState } from "../store";
import {
  arePricingPlansReady,
  isAdmin,
  isLoggedIn,
  isLoggedOut,
  isSiteOwner,
  isTeamManager,
  selectTenantInfo,
  selectUser,
  selectUserPermissions,
  userPictureUrl,
} from "../store/selectors";
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterLinkActive,
} from "@angular/router";
import { environment } from "../../environments/environment";
import { TenantService } from "../services/tenant.service";
import {
  checkIfPlatformSite,
  checkIfSingleSignOnPage, getPlatformSubdomain,
  goToLoginPage,
} from "../common/platform-utils";
import { isAnonymousUser, User } from "../models/user.model";
import { MatDialog } from "@angular/material/dialog";
import { NewsletterDialogComponent } from "../newsletter-dialog/newsletter-dialog.component";
import { UiEventsService } from "../services/ui-events.service";
import { MenuEntry, TenantInfo } from "../models/tenant.model";
import { createDefaultDialogConfig } from "../common/dialog-utils";
import { LoadingService } from "../shared/services/loading.service";
import { ResponsiveLayoutService } from "../services/responsive-layout.service";
import { TranslateModule } from "@ngx-translate/core";
import { AllowRoleDirective } from "../shared/auth/allow-role.directive";
import { AllowPlanDirective } from "../shared/allow-plan/allow-plan.directive";
import { DenyRoleDirective } from "../shared/auth/deny-role.directive";
import { AsyncPipe, NgClass, NgFor, NgIf, NgStyle } from "@angular/common";
import { AiGlobalChatService } from "../ai-global-chat/ai-global-chat.service";
import { UrlBuilderService } from "../services/url-builder.service";
import { MessagesService } from "../shared/services/messages.service";
import { TrialExpirationComponent } from "../shared/trial-expiration/trial-expiration.component";
import { Auth } from "@angular/fire/auth";
import { UserPermissions } from "../models/user-permissions.model";
import { TopMenuSvgSpriteComponent } from "./top-menu-svg-sprite.component";
import { NotificationDialogComponent } from "./notification-dialog/notification-dialog.component";

import { Notification } from "../models/notification.model";
import { Timestamp } from "@firebase/firestore";
import { UserNotificationsService } from "../services/user-notifications.service";
import { userDetailsUpdated } from "../store/user.actions";

import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { SitePagesService } from "../page-builder/site-pages/services/site-pages.service";
import { CustomPage } from "../models/page-builder/custom-page.model";
import { isLifetimeCustomer } from '../common/appsumo-utils';
import { createInstructorPlanDialog } from '../admin/school-settings/change-instructor-plan-dialog/change-instructor-plan-dialog.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { MagicWandIconSvgComponent } from "../svg-sprites/general/magic-wand-icon.svg.component";
import {toObservable} from '@angular/core/rxjs-interop';
import {concatMap, delay, filter, switchMap, tap} from 'rxjs/operators';
import {InstructorPlanService} from '../admin/school-settings/instructor-plan.service';

interface TopMenuData {
  tenant: TenantInfo;
  isLoggedIn: boolean;
  isLoggedOut: boolean;
  pictureUrl: string;
  arePricingPlansReady: boolean;
  user: User;
  showSubscription: boolean;
  isTeamManager: boolean;
  permissions: UserPermissions;
}

declare const Stripe;

@Component({
    selector: "top-menu",
    templateUrl: "./top-menu.component.html",
    styleUrls: ["./top-menu.component.scss"],
    imports: [
        NgIf,
        NgClass,
        RouterLink,
        RouterLinkActive,
        DenyRoleDirective,
        AllowPlanDirective,
        AllowRoleDirective,
        NgFor,
        AsyncPipe,
        TranslateModule,
        TrialExpirationComponent,
        TopMenuSvgSpriteComponent,
        NotificationDialogComponent,
        NgStyle,
        MatButtonModule,
        MatMenuModule,
        MatTooltipModule,
        MagicWandIconSvgComponent
    ]
})
export class TopMenuComponent implements OnInit {
  data$: Observable<TopMenuData>;

  isPlatformSite: boolean;
  isSingleSignOnPage: boolean;
  isLandingPageMode = false;

  isFullScreenMode = false;
  isPhonePortrait: boolean;

  environment: any;

  isTenantLoginPage = false;

  isOpen: boolean = false;
  isOpenNotification: boolean = false;
  customHomePageAvailable: CustomPage;
  private homePageSubscription: Subscription;

  navBarLeftMenu = viewChild("navBarLeftMenu", {
    read: ElementRef
  });

  navBarRightMenu = viewChild("navBarRightMenu", {
    read: ElementRef
  });

  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    console.log("Back button pressed");
    this.aiChatService.endGlobalAiChatSession();
  }

  @HostListener("document:click", ["$event"])
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isOpenNotification = false;
    }
  }

  toggleMenu(event: Event) {
    this.isOpen = !this.isOpen;
  }

  firstExtraMenuEntries: MenuEntry[] = [];

  moreMenuEntries: MenuEntry[] = [];

  injector = inject(Injector);

  instructorPlanService = inject(InstructorPlanService);

  constructor(
    private store: Store<AppState>,
    private afAuth: Auth,
    private router: Router,
    private loading: LoadingService,
    private messages: MessagesService,
    private tenant: TenantService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private uiEvents: UiEventsService,
    private aiChatService: AiGlobalChatService,
    private responsive: ResponsiveLayoutService,
    private ub: UrlBuilderService,
    private elementRef: ElementRef,
    private userNotificationsService: UserNotificationsService,
    private sitePagesService: SitePagesService
  ) {
    this.isPlatformSite = checkIfPlatformSite();
    this.isSingleSignOnPage = checkIfSingleSignOnPage();
    this.environment = environment;
  }

  async ngOnInit() {

    // console.log(`Top menu logo initialized.`, this.data.tenant.topMenuLogoConfig.topMenuLogoUrl);

    this.homePageSubscription = this.sitePagesService.homePage$.subscribe(
      (customPage) => {
        this.customHomePageAvailable = customPage;
      }
    );

    await this.sitePagesService.getHomePage();

    const selectTopMenuData = createSelector(
      selectTenantInfo,
      isLoggedIn,
      isLoggedOut,
      userPictureUrl,
      arePricingPlansReady,
      selectUser,
      isTeamManager,
      selectUserPermissions,
      (
        tenant,
        isLoggedIn,
        isLoggedOut,
        pictureUrl,
        arePricingPlansReady,
        user,
        isTeamManager,
        permissions
      ) => {

        return {
          tenant,
          isLoggedIn,
          isLoggedOut,
          pictureUrl,
          arePricingPlansReady,
          user,
          isTeamManager,
          showSubscription:
            permissions.isAdmin ||
            (arePricingPlansReady && tenant?.isConnectedToStripe),
          permissions,
        };
      }
    );

    this.data$ = this.store.pipe(select(selectTopMenuData));

    combineLatest([
      this.store.pipe(select(selectTenantInfo)),
      toObservable(this.navBarLeftMenu, {injector: this.injector}),
      toObservable(this.navBarRightMenu, {injector: this.injector}),
    ])
      .pipe(
        filter(([tenant ,navBarLeftMenu, navBarRightMenu]) => navBarLeftMenu?.nativeElement && navBarRightMenu?.nativeElement),
      )
      .subscribe(([tenant]) => {

        // console.log(`Recalculating top menu data...` , tenant.customMenu);
        const allExtraMenuEntries = tenant?.customMenu ?? [];

        const firstExtraMenuEntries = [],
          moreMenuEntries = [];

        const navBarMenuLeft = this.navBarLeftMenu()?.nativeElement,
          navBarMenuRight = this.navBarRightMenu()?.nativeElement;

        const navBarLeftRightEdge = navBarMenuLeft?.getBoundingClientRect()?.right ?? 0,
          navBarRightLeftEdge = navBarMenuRight?.getBoundingClientRect()?.left ?? 0;

        // console.log(`navBarLeftRightEdge: ${navBarLeftRightEdge} px`);
        // console.log(`navBarRightLeftEdge: ${navBarRightLeftEdge} px`);

        // let room for the More menu (120px), subtract the space already taken up until the last non-extra menu item
        let widthAvailable = navBarRightLeftEdge - navBarLeftRightEdge - 120;

        // console.log(`widthAvailable: ${widthAvailable} px`);

        for (const menuEntry of allExtraMenuEntries) {
          // 10px is the width of a single character in the menu, 16 is the left and right padding of the menu item, 12 is the left margin
          const menuEntryWidth = menuEntry?.title?.length * 10 + 2*16 + 12;
          if (widthAvailable > menuEntryWidth) {
            // console.log(`Adding to top menu: ${menuEntry.title} with width: ${menuEntryWidth} px.`)
            firstExtraMenuEntries.push(menuEntry);
            widthAvailable -= menuEntryWidth;
          }
          else {
            // console.log(`Adding to More menu: ${menuEntry.title}.`)
            moreMenuEntries.push(menuEntry);
          }
        }

        // console.log(`Menu entries: `, firstExtraMenuEntries, moreMenuEntries);

        this.firstExtraMenuEntries = firstExtraMenuEntries;
        this.moreMenuEntries = moreMenuEntries;
      });

    this.isLandingPageMode =
      (window.location.search.includes("couponCode") ||
        window.location.search.includes("bundleCode")) &&
      !window.location.search.includes("purchaseResult");

    this.uiEvents.fullScreen$.subscribe(
      (fullScreenMode) => (this.isFullScreenMode = fullScreenMode)
    );

    this.responsive.isMobilePortait$.subscribe(
      (isPhonePortrait) => (this.isPhonePortrait = isPhonePortrait)
    );

    this.isTenantLoginPage = document.location.pathname.endsWith("login-page");
  }

  login(startInCoursesPage = false, tenantInfo: TenantInfo) {
    if (document.location.pathname?.endsWith("login-page")) {
      return;
    }
    goToLoginPage(this.router, startInCoursesPage, this.tenant.id);
  }

  logout(tenantInfo: TenantInfo) {
    this.afAuth.signOut().then(() => {
      this.loading.loadingOn();

      window.location.href = "/courses";
    });
  }

  async toggleNotificationDialog(
    userId: string,
    unreadNotificationsCounter: number
  ) {
    try {
      this.isOpenNotification = !this.isOpenNotification;

      if (this.isOpenNotification === true && unreadNotificationsCounter > 0) {
        await this.userNotificationsService.zeroOutNotificationsCounter(userId);

        this.store.dispatch(
          userDetailsUpdated({
            userId,
            changes: { unreadNotificationsCounter: 0 },
          })
        );
      }
    } catch (e) {
      this.isOpenNotification = !this.isOpenNotification;
      this.messages.error(e);
    }
  }

  showNewsletterPopup() {
    const dialogConfig = createDefaultDialogConfig();

    dialogConfig.width = "710px";

    this.dialog.open(NewsletterDialogComponent, dialogConfig);
  }

  onMenuClicked() {
    if (!window.location.hostname.startsWith("login")) {
    }
  }

  joinPartnerProgram() {
    window.open(
      "https://instructor-academy.onlinecoursehost.com/partner-program/",
      "_blank"
    );
  }

  openHelpCenter() {
    window.open("https://help.onlinecoursehost.com", "_blank");
  }

  closeAiGlobalChat() {
    this.aiChatService.endGlobalAiChatSession();
  }

  toggleAiChat(event, tenant: TenantInfo) {
    if (tenant?.instructorPricingPlan == "growth") {
      this.messages.info(
        `The AI Course Creation Assistant is only available if you have a paid plan.`
      );
      return;
    }

    this.aiChatService.toggleAiChat();
    event.stopPropagation();
  }

  isAdminMenuAllowed(data: TopMenuData) {
    return (
      data.permissions.isAdmin ||
      data.permissions.isPlatformAdmin ||
      data.permissions.isCourseAdmin
    );
  }

  isCommunityVisible(data: TopMenuData) {
    //console.log(`Checking community visibility: `, data);
    // check if the platform feature is on or not
    if (!environment?.features?.community) {
      //console.log(`Community feature is disabled.`);
      return false;
    }

    //check if user is banned from the community
    if (data.user?.communityMemberSettings?.banned) {
      return false;
    }
    // for the moment the community is only visible to platform admins
    if (data?.permissions?.isPlatformAdmin) {
      //console.log(`Community is visible to platform admins.`);
      return true;
    }
    // the community is visible to website admins and course admins
    if (data?.permissions?.isAdmin || data?.permissions?.isCourseAdmin) {
      //console.log(`Community is visible to website admins and course admins.`);
      return true;
    }
    // if it's a student user and the community is explicitly enabled
    if (
      !data?.permissions?.isAdmin &&
      !data?.permissions?.isCourseAdmin &&
      !(data?.tenant?.communitySettings?.disabled ?? true)
    ) {
      //console.log(`Community is visible to students.`);
      return true;
    }
    // if the community is free and the user is not logged in, allow access
    if (
      isAnonymousUser(data?.user) &&
      data?.tenant?.communitySettings?.communityAccess == "free" &&
      data?.tenant?.communitySettings?.disabled == false
    ) {
      return true;
    }
    // all other cases hide the community from the top menu
    //console.log(`Community is hidden.`);
    return false;
  }

  isPageBuilderVisible(data: TopMenuData) {
    const active = environment?.features?.pageBuilder || data?.permissions?.isPlatformAdmin;
    return active && data?.permissions?.isAdmin;
  }

  isUpgradeButtonVisible(data: TopMenuData) {
    // the button is only visible to logged-in users
    if (isAnonymousUser(data.user)) {
      return false;
    }
    // the upgrade button is only visible to admins
    if (!data.permissions.isAdmin) {
      return false;
    }
    const { instructorPricingPlan } = data.tenant;
    // there is no upgrade possible above the unlimited plan
    if (data.tenant.instructorPricingPlan == "unlimited") {
      return false;
    }

    // there is no upgrade before activating a plan
    if (data.tenant.instructorPricingPlan == "growth") {
      return false;
    }

    // for the moment, don't show the upgrade button to old lifetime plans, just to the new plan with 5GB limit
    //if (instructorPricingPlan == "lifetime" || instructorPricingPlan == "lifetime-pro") {
    //  return false;
    //}
    // check if the button is active on this environment
    return environment?.features?.upgradeButton;
  }

  async onUpgradeBtnClicked(data: TopMenuData) {
    if (isLifetimeCustomer(data.tenant)) {
      await this.router.navigateByUrl("/upgrade-to-unlimited");
    }
    else {
      createInstructorPlanDialog(this.dialog)
        .pipe(
          concatMap(plan => this.loading.showLoaderUntilCompleted(
              this.instructorPlanService.updateInstructorPlan(
                false,
                this.tenant.tenant['email'],
                plan?.selectedInstructorPlanId,
                plan?.selectedPlanFrequency
              )
            )
          ),
          tap(response => {

            const stripe = Stripe(response.stripePublicKey);

            stripe.redirectToCheckout({
              sessionId: response.sessionId
            });

          })
        )
        .subscribe();
    }
  }

  closeNotificationDialog() {
    this.isOpenNotification = false;
  }

  evaluateCustomMenuHref(customMenu: MenuEntry) {

    if(customMenu?.['dynamic']) {

      if(customMenu?.link) {
        const {
          pathname
        } = new URL(customMenu.link);

        const origin = location.origin

        if(pathname.split('/').filter(Boolean)[0] === 'pages') {

          return origin + pathname

        }

      }

    }

    return customMenu?.link

  }

  ngOnDestroy() {
    this.homePageSubscription.unsubscribe();
  }

  // isAnalyticsVisible(data: TopMenuData) {
  //   const active = environment?.features?.analytics || data?.permissions?.isPlatformAdmin;
  //   return active && data?.permissions?.isAdmin;
  // }
}
