import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptorsFromDi} from '@angular/common/http';
import {LoadingService} from './shared/services/loading.service';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSliderModule} from '@angular/material/slider';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CustomTranslationsLoader} from './services/custom-translations-loader.service';
import {UserEffects} from './store/user.effects';
import {PlatformEffects} from './store/platform.effects';
import {CouponEffects} from './store/coupon.effects';
import {ContentEffects} from './store/content.effects';
import {UserLessonStatusEffects} from './store/user-lesson-status.effects';
import {LatestLessonEffects} from './store/latest-lesson.effects';
import {QuestionEffects} from './store/question.effects';
import {AnswersEffects} from './store/answers.effects';
import {AuthorsEffects} from './store/authors.effects';
import {AssignmentSolutionEffects} from './store/assignment-solution.effects';
import {UserActivityLogEffects} from './store/user-activity-log.effects';
import {DripConfigListEffects} from './store/drip-config-list.effects';
import {RouterState, StoreRouterConnectingModule} from '@ngrx/router-store';
import {TenantService} from './services/tenant.service';
import {CoursesDBService} from './services/courses-db.service';
import {DescriptionsDbService} from './services/descriptions-db.service';
import {LessonsDBService} from './services/lessons-db.service';
import {TenantsDBService} from './services/tenants-db.service';
import {PlatformGuard} from './services/platform.guard';
import {CheckIfHomePageActiveGuard} from './homepage/check-if-home-page-active.guard';
import {DefaultBrandGuard} from './services/default-brand.guard';
import {FileUploadService} from './services/file-upload.service';
import {ViewCoursesResolver} from './services/view-courses.resolver';
import {LoadCourseDetailResolver} from './services/load-course-detail.resolver';
import {ActiveLessonResolver} from './services/active-lesson.resolver';
import {LoginGuard} from './login/login.guard';
import {PaymentsService} from './services/payments.service';
import {CouponsService} from './services/coupons.service';
import {DripScheduleConfigService} from './services/drip-schedule-config.service';
import {provideRouter, withInMemoryScrolling, withRouterConfig, withViewTransitions} from '@angular/router';
import {routes} from './routes.config';
import {AuthInterceptor} from './services/auth.interceptor';
import {TestUserInterceptor} from './services/test-user.interceptor';
import {SessionLanguageInterceptor} from './services/session-language.interceptor';
import {provideAnimations} from '@angular/platform-browser/animations';
import {DescriptionEffects} from './store/description.effects';
import {LessonEffects} from './store/lesson.effects';
import {CourseEffects} from './store/course.effects';
import {EffectsModule} from '@ngrx/effects';
import {environment} from '../environments/environment';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {reducers} from './store';
import {StoreModule} from '@ngrx/store';
import {LayoutModule} from '@angular/cdk/layout';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatBadgeModule} from '@angular/material/badge';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {LoadRoomsResolver} from './community/services/load-rooms.resolver';

import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {getAuth, provideAuth} from '@angular/fire/auth';
import {getStorage, provideStorage} from '@angular/fire/storage';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';

// uncomment if you need to migrate old code that uses the compat API
// import {AngularFireStorageModule} from '@angular/fire/compat/storage';
// import {AngularFireAuthModule} from '@angular/fire/compat/auth';
// import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
// import {AngularFireModule} from '@angular/fire/compat';

export function HttpLoaderFactory(http: HttpClient, loading: LoadingService) {
  return new CustomTranslationsLoader(http, loading);
}


export const appConfig: ApplicationConfig = {
  providers: [
    // providers for the new @angular/fire API - keep these
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => {
      const firestore = getFirestore();
      //connectFirestoreEmulator(firestore, 'localhost', 8080);
      //enableIndexedDbPersistence(firestore);
      return firestore;
    }),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),

    importProvidersFrom(
      BrowserModule, TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient, LoadingService]
        },
        defaultLanguage: 'en'
      }),
      MatMenuModule,
      MatButtonModule,
      MatIconModule, MatCardModule, MatTabsModule, MatListModule, MatToolbarModule, MatInputModule,
      MatTableModule, MatPaginatorModule, MatSortModule, MatProgressBarModule, MatProgressSpinnerModule,
      MatDialogModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatExpansionModule,
      MatSlideToggleModule, MatSliderModule, MatGridListModule, MatCheckboxModule, MatTooltipModule,
      MatAutocompleteModule, MatButtonToggleModule, MatRadioModule, FormsModule, ReactiveFormsModule,
      DragDropModule,
      MatBadgeModule,
      MatSnackBarModule,
      LayoutModule,

      // these are the providers for the old Firebase API
      // uncomment if you need to migrate old code that uses the compat API
      //AngularFireModule.initializeApp(environment.firebase),
      //AngularFirestoreModule,
      //AngularFireAuthModule,
      //AngularFireStorageModule,

      StoreModule.forRoot(reducers, {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          //        strictActionSerializability:true,
          //        strictStateSerializability:true
        }
      }),
      //!environment.production ? StoreDevtoolsModule.instrument({connectInZone: true}) : [],
      StoreDevtoolsModule.instrument({connectInZone: true}),
      EffectsModule.forRoot([
        CourseEffects,
        LessonEffects,
        DescriptionEffects,
        UserEffects,
        PlatformEffects,
        CouponEffects,
        ContentEffects,
        UserLessonStatusEffects,
        LatestLessonEffects,
        QuestionEffects,
        AnswersEffects,
        AuthorsEffects,
        AssignmentSolutionEffects,
        UserActivityLogEffects,
        DripConfigListEffects
      ]),
      StoreRouterConnectingModule.forRoot({
        routerState: RouterState.Minimal,
        stateKey: 'router'
      })),
    TenantService,
    CoursesDBService,
    DescriptionsDbService,
    LessonsDBService,
    TenantsDBService,
    PlatformGuard,
    CheckIfHomePageActiveGuard,
    DefaultBrandGuard,
    FileUploadService,
    ViewCoursesResolver,
    LoadCourseDetailResolver,
    ActiveLessonResolver,
    LoadRoomsResolver,
    LoginGuard,
    PaymentsService,
    CouponsService,
    DripScheduleConfigService,
    provideRouter(routes,

      withInMemoryScrolling({
        anchorScrolling: 'enabled',
        scrollPositionRestoration: 'top',
      }),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      }),
      withViewTransitions()
    ),


    //scrollOffset: [0, 70],


    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TestUserInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionLanguageInterceptor,
      multi: true,
    },


    // remove provideAnimations()
    provideAnimationsAsync(),


    provideHttpClient(withInterceptorsFromDi(), withFetch())
  ]
}
